export default [
  {
    title: '통계',
    icon: 'CastIcon',
    type: 'druginfo',
    children: [
      {
        title: '약품정보',
        route: 'clients-druginfo-stast-list',
      },
      {
        title: '라이브DI',
        route: 'clients-livedi-stast-list',
      },
    ],
  },
  {
    title: '간편처방등록 통계',
    icon: 'CastIcon',
    type: 'druglanding',
    children: [
      {
        title: '결과 정보',
        route: 'clients-druglanding-stast-list',
      },
    ],
  },
  {
    title: '설문조사 통계',
    icon: 'UsersIcon',
    type: 'survey',
    children: [
      {
        title: '결과 정보',
        route: 'clients-survey-stast-list',
      },
    ],
  },
  {
    title: '웹 심포지엄 통계',
    icon: 'PieChartIcon',
    type: 'seminar',
    children: [
      {
        title: '결과 정보',
        route: 'clients-seminar-stast-list',
      },
    ],
  },
]
