var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-cart mr-25",
      attrs: { "menu-class": "dropdown-menu-media", right: "" },
      on: { show: _vm.fetchItems },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("feather-icon", {
                staticClass: "text-body",
                attrs: {
                  badge: _vm.$store.state["app-ecommerce"].cartItemsCount,
                  icon: "ShoppingCartIcon",
                  size: "21",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("li", { staticClass: "dropdown-menu-header" }, [
        _c(
          "div",
          { staticClass: "dropdown-header d-flex" },
          [
            _c("h4", { staticClass: "notification-title mb-0 mr-auto" }, [
              _vm._v(" My Cart "),
            ]),
            _c("b-badge", { attrs: { pill: "", variant: "light-primary" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$store.state["app-ecommerce"].cartItemsCount) +
                  " Items "
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm.items.length
        ? _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scrollable-container media-list scroll-area",
              attrs: { settings: _vm.perfectScrollbarSettings, tagname: "li" },
            },
            _vm._l(_vm.items, function (item) {
              return _c(
                "b-media",
                {
                  key: item.name,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "aside",
                        fn: function () {
                          return [
                            _c("b-img", {
                              attrs: {
                                src: item.image,
                                alt: item.name,
                                rounded: "",
                                width: "62px",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("feather-icon", {
                    staticClass: "cart-item-remove cursor-pointer",
                    attrs: { icon: "XIcon" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.removeItemFromCart(item.id)
                      },
                    },
                  }),
                  _c("div", { staticClass: "media-heading" }, [
                    _c(
                      "h6",
                      { staticClass: "cart-item-title" },
                      [
                        _c("b-link", { staticClass: "text-body" }, [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ]),
                      ],
                      1
                    ),
                    _c("small", { staticClass: "cart-item-by" }, [
                      _vm._v("By " + _vm._s(item.brand)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cart-item-qty ml-1" },
                    [
                      _c("b-form-spinbutton", {
                        attrs: { min: "1", size: "sm" },
                        model: {
                          value: item.qty,
                          callback: function ($$v) {
                            _vm.$set(item, "qty", $$v)
                          },
                          expression: "item.qty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", { staticClass: "cart-item-price" }, [
                    _vm._v(" $" + _vm._s(item.price) + " "),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.items.length
        ? _c(
            "li",
            { staticClass: "dropdown-menu-footer" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mb-1" },
                [
                  _c("h6", { staticClass: "font-weight-bolder mb-0" }, [
                    _vm._v(" Total: "),
                  ]),
                  _c(
                    "h6",
                    { staticClass: "text-primary font-weight-bolder mb-0" },
                    [_vm._v(" $" + _vm._s(_vm.totalAmount) + " ")]
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(255, 255, 255, 0.15)",
                      expression: "'rgba(255, 255, 255, 0.15)'",
                      modifiers: { 400: true },
                    },
                  ],
                  attrs: {
                    variant: "primary",
                    block: "",
                    to: { name: "apps-e-commerce-checkout" },
                  },
                },
                [_vm._v(" Checkout ")]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.items.length
        ? _c("p", { staticClass: "m-0 p-1 text-center" }, [
            _vm._v(" Your cart is empty "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }